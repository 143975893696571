<template>
<!--  <div id="nav">-->
<!--    <router-link to="/">Home</router-link> |-->
<!--  </div>-->
  <router-view/>
</template>

<style lang="scss">
@import "src/assets/css/custom";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

</style>
